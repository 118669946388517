import styled from "@emotion/styled";
import React, { useMemo } from "react";
import { getCountryImage } from "../../helper/flags";
import Box from "../Box";

const Name = styled.div`
  font-size: 16px;
`;

const FleetName = styled.div`
  font-size: 24px;
  margin-top: 10px;
  margin-bottom: 10px;
`;

const Count = styled.div`
  font-size: 12px;
`;

const Country = ({ name, fleetName, shipCount, ...props }) => {
  const countryImage = useMemo(() => getCountryImage(name), [name]);

  return (
    <Box image={countryImage} {...props}>
      <Name>{name}</Name>
      <FleetName>{fleetName}</FleetName>
      <Count>
        {shipCount} Ship{shipCount !== 1 && "s"}
      </Count>
    </Box>
  );
};

export default Country;
