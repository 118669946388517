import create from "zustand";
import OfflineStorage, { storages } from "../helper/offlineStorage";

const printStorage = new OfflineStorage(storages.print);

const useStore = create((set, get) => ({
  shipIds: printStorage.get() || [],
  addShip: (shipId) => {
    const state = get();
    const shipIds = [...state.shipIds, shipId];
    set({ shipIds });
    printStorage.store(shipIds);
  },
  removeShip: (shipId) => {
    const state = get();
    const shipIds = state.shipIds.filter((id) => id !== shipId);
    set({ shipIds });
    printStorage.store(shipIds);
  },
  toggleShip: (shipId) => {
    const state = get();
    if (state.shipIds.includes(shipId)) {
      state.removeShip(shipId);
    } else {
      state.addShip(shipId);
    }
  },
  clear: () => {
    set({ shipIds: [] });
    printStorage.store([]);
  },
}));

export default useStore;
