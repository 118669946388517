import create from "zustand";
import api from "../apis/api";
import { sortBy } from "../helper/helpers";
import OfflineStorage, { storages } from "../helper/offlineStorage";
import { transformShip, transformWeaponSystem } from "../helper/transformers";

const countryStorage = new OfflineStorage(storages.countries);
const traitStorage = new OfflineStorage(storages.traits);
const directionStorage = new OfflineStorage(storages.directions);
const typeStorage = new OfflineStorage(storages.types);

const useStore = create((set, get) => ({
  loading: false,
  initialLoadDone: false,
  countries: null,
  ships: null,
  traits: null,
  types: null,
  directions: null,
  bucket: null,
  images: null,

  setShips: (ships) => {
    set({ ships });
  },

  /**
   * Fetch everything
   */
  fetchData: async () => {
    set({ loading: true });

    // Application data
    await Promise.all([
      // Countries
      countryStorage.needsRefresh()
        ? api.countries.all().then((countries) => {
            countryStorage.store(countries);
            return countries;
          })
        : countryStorage.get(),
      // Ships
      api.ships.all(),
      // Traits
      traitStorage.needsRefresh()
        ? api.traits.all().then((traits) => {
            traitStorage.store(traits);
            return traits;
          })
        : traitStorage.get(),
      // Types
      typeStorage.needsRefresh()
        ? api.types.all().then((types) => {
            typeStorage.store(types);
            return types;
          })
        : typeStorage.get(),
      // Directions
      directionStorage.needsRefresh()
        ? api.directions.all().then((directions) => {
            directionStorage.store(directions);
            return directions;
          })
        : directionStorage.get(),
    ])
      .then((values) => {
        const traits = values[2].sort((a, b) => sortBy(a, b, "name"));
        set({
          countries: values[0],
          ships: values[1].map(transformShip),
          traits: traits,
          types: values[3],
          directions: values[4],
          loading: false,
          initialLoadDone: true,
          images: values[5],
        });
      })
      .catch((error) => {
        console.error(error.message);
      });
  },
  /**
   * Set the ships image optimistically without reloading.
   */
  updateShipImageOptimistic: (shipId, image) => {
    const state = get();
    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.image = image;
        }
        return ship;
      }),
    });
  },
  /**
   * Add the given ship trait optimistically without reloading.
   */
  addShipTraitOptimisitc: (shipId, trait) => {
    const state = get();
    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.traits.push(trait);
        }
        return ship;
      }),
    });
  },
  /**
   * Remove the given ship trait optimistically without reloading.
   */
  removeShipTraitOptimistic: (shipId, traitId) => {
    const state = get();
    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.traits = ship.traits.filter(
            (trait) => trait.id !== Number(traitId)
          );
        }
        return ship;
      }),
    });
  },
  /**
   * Add the given weapon system optimistically without reloading.
   */
  addWeaponSystemOptimistic: (shipId, weaponSystem) => {
    const state = get();
    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.weaponSystems.push(transformWeaponSystem(weaponSystem));
        }
        return ship;
      }),
    });
  },
  /**
   * Update the given weapon system optimistically without reloading.
   */
  updateWeaponSystemOptimistic: (shipId, weaponSystem) => {
    const state = get();
    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.weaponSystems = ship.weaponSystems.map((ws) => {
            if (ws.id === weaponSystem.id) {
              return weaponSystem;
            }
            return ws;
          });
        }
        return ship;
      }),
    });
  },
  /**
   * Add the given weapon system trait optimistically without reloading.
   */
  addWeaponSystemTraitOptimistic: (shipId, weaponSystemId, trait) => {
    const state = get();

    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.weaponSystems = ship.weaponSystems.map((weaponSystem) => {
            if (weaponSystem.id === weaponSystemId) {
              return {
                ...weaponSystem,
                traits: [...weaponSystem.traits, trait],
              };
            }
            return weaponSystem;
          });
        }
        return ship;
      }),
    });
  },
  /**
   * Remove the given weapon system trait optimistically without reloading.
   */
  removeWeaponSystemTraitOptimistic: (shipId, weaponSystemId, traitId) => {
    const state = get();

    set({
      ships: state.ships.map((ship) => {
        if (ship.id === Number(shipId)) {
          ship.weaponSystems = ship.weaponSystems.map((weaponSystem) => {
            if (weaponSystem.id === weaponSystemId) {
              return {
                ...weaponSystem,
                traits: weaponSystem.traits.filter(
                  (weaponSystemTrait) =>
                    weaponSystemTrait.id !== Number(traitId)
                ),
              };
            }
            return weaponSystem;
          });
        }
        return ship;
      }),
    });
  },
  /**
   * Reload all ships.
   */
  reloadShips: async () => {
    const ships = await api.ships.all();
    set({ ships: ships.map(transformShip) });
  },
  /**
   * Reload the ship for the given ship id.
   */
  reloadShip: async (shipId) => {
    const state = get();
    const freshShip = await api.ships.find(shipId);
    if (state.ships.find((ship) => ship.id === freshShip.id)) {
      set({
        ships: state.ships.map((ship) =>
          ship.id === freshShip.id ? transformShip(freshShip) : ship
        ),
      });
    } else {
      set({
        ships: [...state.ships, transformShip(freshShip)],
      });
    }
    return null;
  },
}));

export default useStore;
