import React from "react";
import { Toaster as ToasterItem } from "react-hot-toast";

const Toaster = () => {
  return (
    <div>
      <ToasterItem
        toastOptions={{
          position: "top-right",
          // Define default options
          className: "",
          duration: 3000,
          style: {
            background: "var(--color-background)",
            color: "var(--color-text)",
            border: "1px solid var(--color-grid)",
          },

          // Default options for specific types
          success: {
            duration: 3000,
            theme: {
              primary: "var(--color-accent)",
              secondary: "black",
            },
          },
        }}
      />
    </div>
  );
};

export default Toaster;
