import { sort, sortBy } from "./helpers";

export const transformShip = (ship) => {
  return {
    id: ship.id,
    points: ship.points,
    country: transformCountry(ship.countries),
    country_id: ship.country_id,
    class: ship.class,
    name: ship.name,
    type: transformShipType(ship.ship_types),
    type_id: ship.type_id,
    flankSpeed: ship.flank_speed,
    armour: ship.armour,
    hull: `${ship.hull_health}/${ship.hull_crippled}`,
    hullHealth: ship.hull_health,
    hullCrippled: ship.hull_crippled,
    flights: ship.flights,
    traits: ship.ship_traits.map(transformTrait),
    weaponSystems: ship.weapon_systems
      .sort((a, b) => sortBy(a, b, "id"))
      .map(transformWeaponSystem),
    image: ship.image,
  };
};

export const transformWeaponSystem = (weaponSystem) => {
  return {
    id: weaponSystem.id,
    shipId: weaponSystem.ship_id,
    name: weaponSystem.name,
    pointBlank: weaponSystem.point_blank,
    short: weaponSystem.short,
    long: weaponSystem.long,
    extreme: weaponSystem.extreme,
    ad: weaponSystem.attack_dice,
    ap: weaponSystem.armour_piercing,
    dd: weaponSystem.damage_dice,
    traits: weaponSystem.traits_weapon_systems
      .sort((a, b) => sort(a.traits.name, b.traits.name))
      .map(transformTrait),
    directions: weaponSystem.directions_weapon_systems.map((direction) =>
      transformDirection(direction.directions)
    ),
  };
};

export const transformTrait = (trait) => {
  return {
    id: trait.traits.id,
    hasValue: trait.traits.has_value,
    value: trait.value,
    name: trait.traits.name,
  };
};

export const transformDirection = (direction) => ({
  id: direction.id,
  name: direction.name,
});
export const transformCountry = (country) => country.name;

export const transformShipType = (shipType) => shipType.name;
