import styled from "@emotion/styled";
import React from "react";
import { countryImages } from "../../../helper/flags";

const getFlagImage = (country) =>
  countryImages[country.replace(" ", "").toLowerCase()] ?? "";
const Container = styled.div`
  display: flex;
  height: 30px;
  width: 45px;

  img {
    // filter: grayscale(100%);
    object-fit: fill;
    width: 100%;
  }
`;

const Flag = ({ country }) => {
  return (
    <Container>
      <img src={getFlagImage(country)} alt={country} />{" "}
    </Container>
  );
};

export default Flag;
