import styled from "@emotion/styled";
import React from "react";

const Container = styled.div`
  display: flex;
  background-image: url(${(props) => props.image});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  width: 70%;

  .compact & {
    width: 50%;
  }
`;

const ShipImage = ({ image }) => {
  return <Container image={image}></Container>;
};

export default ShipImage;
