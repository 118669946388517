import styled from "@emotion/styled";
import React, { useMemo } from "react";
import useDatabaseStore from "../../../../../stores/databaseStore";
import FiringArc from "../../../../ProfileCard/WeaponSystems/FiringArc";

const Inputs = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Row = styled.div`
  display: grid;
  grid-template-columns: 2fr 4fr;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const CheckboxLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const Label = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

const FiringArcWrapper = styled.div`
  display: flex;
`;

const FiringArcSelector = ({
  selectedDirections = [],
  onChange = () => {},
}) => {
  const { directions } = useDatabaseStore((state) => ({
    directions: state.directions,
  }));

  const localOnChange = (event) => {
    const value = Number(event.target.value);
    let changed = [...selectedDirections];
    if (selectedDirections.includes(value)) {
      changed = selectedDirections.filter(
        (directionId) => directionId !== value
      );
    } else {
      changed = [...selectedDirections, value];
    }
    onChange(changed);
  };

  const directionsForArc = useMemo(
    () =>
      selectedDirections.map(
        (selected) =>
          directions.find((direction) => direction.id === selected).name
      ),
    [selectedDirections]
  );

  return (
    <Row>
      <Label>
        <div>Firing Arc</div>
        <FiringArcWrapper>
          <FiringArc directions={directionsForArc} />
        </FiringArcWrapper>
      </Label>
      <Inputs>
        {directions.map((direction) => (
          <CheckboxLabel key={direction.id}>
            <input
              type="checkbox"
              value={direction.id}
              checked={selectedDirections.includes(direction.id)}
              onChange={localOnChange}
            />{" "}
            {direction.name}
          </CheckboxLabel>
        ))}
      </Inputs>
    </Row>
  );
};

export default FiringArcSelector;
