import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import BackLink from "../components/BackLink";
import ShipEditor from "../components/ShipEditor";
import useDatabaseStore from "../stores/databaseStore";

const Ship = () => {
  let { shipId } = useParams();
  const { ships, loading, initialLoadDone } = useDatabaseStore((state) => ({
    ships: state.ships,
    loading: state.loading,
    initialLoadDone: state.initialLoadDone,
  }));

  const ship = useMemo(() => {
    if (shipId && !loading && ships) {
      const ship = ships.find((ship) => ship.id === Number(shipId));
      if (ship) {
        return ship;
      }
    }
    return null;
  }, [shipId, ships, loading]);

  return (
    !loading &&
    initialLoadDone &&
    ship && (
      <>
        <BackLink to={`/countries/${ship.country_id}/ships`}>Ships</BackLink>
        <ShipEditor ship={ship} />
      </>
    )
  );
};

export default Ship;
