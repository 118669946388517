import React from "react";
import { Routes, Route, BrowserRouter as Router } from "react-router-dom";
import Home from "./pages/home";
import Login from "./pages/login";
import Ships from "./pages/ships";
import { useEffect } from "react";
import { supabase } from "./supabaseClient";
import useAuthStore from "./stores/authStore";
import useDatabaseStore from "./stores/databaseStore.js";
import useConsentStore from "./stores/consentStore";
import Page from "./components/Page";
import Container from "./components/Container";
import Spinner from "./components/Spinner";
import Countries from "./pages/countries";
import Imprint from "./pages/imprint";
import Ship from "./pages/ship";
import ShipCreator from "./pages/shipCreator";
import Confirm from "./components/Confirm";
import PrintList from "./pages/printList";
import Issues from "./pages/issues";
import CookieConsent from "./components/CookieConsent";

const App = () => {
  const consentConfirmed = useConsentStore((state) => state.confirmed);
  const { isAuthenticated, session, setSession } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    session: state.session,
    setSession: state.setSession,
  }));
  const { loading, fetchData } = useDatabaseStore((state) => ({
    loading: state.loading,
    fetchData: state.fetchData,
  }));

  useEffect(() => {
    if (consentConfirmed) {
      supabase.auth.getSession().then(({ data: { session } }) => {
        setSession(session);
      });

      supabase.auth.onAuthStateChange((_event, session) => {
        setSession(session);
      });
    }
  }, [setSession, consentConfirmed]);

  useEffect(() => {
    if (isAuthenticated()) {
      fetchData();
    }
  }, [session, isAuthenticated, fetchData]);

  return (
    <Router>
      <Page withNavigation={isAuthenticated()}>
        {isAuthenticated() ? (
          <Container>
            <Routes>
              <Route path="/" element={<Home />} />

              <Route path="/countries" element={<Countries />} />
              <Route path="/countries/:countryId/ships" element={<Ships />} />
              <Route path="/create" element={<ShipCreator />} />
              <Route path="/ships/:shipId" element={<Ship />} />
              <Route path="/print-list" element={<PrintList />} />
              <Route path="/issues" element={<Issues />} />
              <Route path="/imprint" element={<Imprint />} />
            </Routes>
          </Container>
        ) : (
          <Routes>
            <Route path="/imprint" element={<Imprint />} />
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </Page>
      <Confirm />
      <CookieConsent />
      {loading && <Spinner />}
    </Router>
  );
};

export default App;
