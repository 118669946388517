import create from "zustand";
import { supabase } from "../supabaseClient";

const useStore = create((set, get) => ({
  session: null,
  setSession: (session) => set({ session }),
  isAuthenticated: () => {
    const state = get();
    return state.session !== null;
  },
  logout: () => {
    supabase.auth.signOut();
    set({ session: null });
  },
}));

export default useStore;
