import styled from "@emotion/styled";
import React from "react";
import Icon from "../components/Icon";
import Issues from "../components/Issues";
import issues from "../issues";

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const Report = styled.div`
  display: flex;
  gap: 20px;
  border: 1px solid var(--color-grid);
  border-radius: 4px;
  padding: 10px;
`;

const IssuesPage = () => {
  return (
    <>
      <Columns>
        <h1>Issues and known Bugs</h1>
        <Report>
          <Icon type="send" color="accent" />{" "}
          <div>
            Please report bugs to{" "}
            <a href="mailto:info@webdevalex.de">info@webdevalex.de</a>
          </div>
        </Report>
      </Columns>
      <Issues data={issues} />
    </>
  );
};

export default IssuesPage;
