import japan from "../images/flags/japan.png";
import usa from "../images/flags/usa.png";
import germany from "../images/flags/germany.png";
import france from "../images/flags/france.png";
import italy from "../images/flags/italy.png";
import greatBritain from "../images/flags/great-britain.png";

export const countryImages = {
  japan,
  unitedstates: usa,
  germany,
  greatbritain: greatBritain,
  france,
  italy,
};

export const normalizeCountryName = (name) =>
  name.replace(" ", "").toLowerCase();

export const getCountryImage = (countryName) => {
  const normalized = normalizeCountryName(countryName);
  return countryImages[normalized] ? countryImages[normalized] : null;
};
