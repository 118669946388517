import styled from "@emotion/styled";
import React, { useState } from "react";
import Button from "../../../Button";
import Input from "../../../Input";
import TraitSelect from "../../TraitSelect";
import useDatabaseStore from "../../../../stores/databaseStore";
import useConfirmStore from "../../../../stores/confirmStore";
import FiringArcSelector from "./FiringArcSelector";
import toast from "react-hot-toast";
import Icon from "../../../Icon";
import api from "../../../../apis/api";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TraitsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-top: 20px;
`;

const Actions = styled.div`
  display: flex;
  gap: 20px;
`;

const SaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const WeaponSystemForm = ({ weaponSystem, onFormFinish }) => {
  let { shipId } = useParams();
  const [firingDirections, setFiringDirections] = useState(
    weaponSystem ? weaponSystem.directions.map((direction) => direction.id) : []
  );
  const {
    directions,
    addWeaponSystemOptimistic,
    addWeaponSystemTraitOptimistic,
    removeWeaponSystemTraitOptimistic,
  } = useDatabaseStore((state) => ({
    directions: state.directions,
    addWeaponSystemOptimistic: state.addWeaponSystemOptimistic,
    addWeaponSystemTraitOptimistic: state.addWeaponSystemTraitOptimistic,
    removeWeaponSystemTraitOptimistic: state.removeWeaponSystemTraitOptimistic,
  }));

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const { confirm } = useConfirmStore((state) => ({ confirm: state.confirm }));

  const onSubmit = async (data) => {
    const toastId = toast.loading(
      `${weaponSystem ? "Updating" : "Creating"} Weapon System...`
    );

    const payload = {
      name: data.name,
      directions: firingDirections,
      pointBlank: data.pointBlank,
      short: data.short,
      long: data.long,
      extreme: data.extreme,
      ad: data.ad,
      ap: data.ap,
      dd: data.dd,
    };

    if (weaponSystem) {
      await Promise.all([
        await api.weaponSystems.update(weaponSystem.id, payload),
        await api.weaponSystems.directions.update(
          weaponSystem.id,
          getDirectionsToAdd(),
          getDirectionsToDelete()
        ),
      ]);
    } else {
      const newWeaponSystem = await api.weaponSystems.create(shipId, payload);
      addWeaponSystemOptimistic(newWeaponSystem);
    }
    onFormFinish();
    toast.success(`Weapon System ${weaponSystem ? "updated" : "created"}!`, {
      id: toastId,
    });
  };

  const getDirectionsToAdd = () => {
    return firingDirections.filter(
      (dataDirection) =>
        !weaponSystem.directions
          .map((direction) => direction.id)
          .includes(dataDirection)
    );
  };

  const getDirectionsToDelete = () => {
    return weaponSystem.directions
      .map((direction) => direction.id)
      .filter((wsDirection) => !firingDirections.includes(wsDirection));
  };

  const onTraitAdd = async (trait, value) => {
    const toastId = toast.loading("Adding Trait...");

    const traitObject = {
      id: trait.id,
      name: trait.name,
      hasValue: trait.has_value,
      value,
    };

    await api.weaponSystems.traits.add(weaponSystem.id, trait.id, value);

    addWeaponSystemTraitOptimistic(
      weaponSystem.shipId,
      weaponSystem.id,
      traitObject
    );
    toast.success("Trait added!", {
      id: toastId,
    });
  };

  const onTraitDelete = async (traitId) => {
    const toastId = toast.loading("Deleting Trait...");
    await api.weaponSystems.traits.delete(weaponSystem.id, traitId);

    removeWeaponSystemTraitOptimistic(
      weaponSystem.shipId,
      weaponSystem.id,
      traitId
    );
    toast.success("Trait deleted!", {
      id: toastId,
    });
  };

  const onDuplicate = async () => {
    const toastId = toast.loading("Duplicating Weapon System...");

    await api.weaponSystems.duplicate(shipId, weaponSystem);

    onFormFinish();
    toast.success("Weapon System duplicated!", {
      id: toastId,
    });
  };

  const onDelete = async () => {
    const toastId = toast.loading("Deleting Weapon System...");

    await api.weaponSystems.delete(weaponSystem, directions);
    onFormFinish();
    toast.success("Weapon System deleted!", {
      id: toastId,
    });
  };

  return (
    <>
      <Header>
        <h3>{weaponSystem ? "Edit" : "Add"} a Weapons System</h3>

        {weaponSystem && (
          <Actions>
            <Button
              title="Duplicate Weapon System"
              color="simple"
              onClick={() =>
                confirm(
                  "Dou you want to duplicate that weapon system?",
                  onDuplicate
                )
              }
            >
              <Icon type="copy" />
            </Button>
            <Button
              title="Delete Weapon System"
              color="simple"
              onClick={() =>
                confirm(
                  "Do you really want to delete that Weapon System?",
                  onDelete
                )
              }
            >
              <Icon type="delete" />
            </Button>
          </Actions>
        )}
      </Header>
      <FiringArcSelector
        selectedDirections={firingDirections}
        onChange={setFiringDirections}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <Inputs>
          <Input
            label="Name"
            name="name"
            defaultValue={weaponSystem?.name}
            required
            register={register}
            error={errors.name}
            // onChange={onChange}
          />
          <Input
            label="Point Blank"
            name="pointBlank"
            defaultValue={weaponSystem?.pointBlank}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Short"
            name="short"
            defaultValue={weaponSystem?.short}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Long"
            name="long"
            defaultValue={weaponSystem?.long}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Extreme"
            name="extreme"
            defaultValue={weaponSystem?.extreme}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Attack Dice"
            name="ad"
            defaultValue={weaponSystem?.ad}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Armour Piercing"
            name="ap"
            defaultValue={weaponSystem?.ap}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <Input
            label="Damage Dice"
            name="dd"
            defaultValue={weaponSystem?.dd}
            // onChange={onChange}
            register={register}
            type="number"
          />
          <SaveButtonWrapper>
            &nbsp;
            <Button type="submit">
              {weaponSystem ? "Save" : "Create Weapon System"}
            </Button>
          </SaveButtonWrapper>
        </Inputs>
      </form>
      {weaponSystem && (
        <TraitsWrapper>
          <div>Traits</div>
          <TraitSelect
            selectedTraits={weaponSystem.traits}
            onAdd={onTraitAdd}
            onDelete={onTraitDelete}
          />
        </TraitsWrapper>
      )}
    </>
  );
};

export default WeaponSystemForm;
