import styled from "@emotion/styled";
import React from "react";
import { traitWithValue } from "../../../helper/helpers";

const Container = styled.div`
  width: 30%;
  border: 1px var(--grid-border-type) var(--color-grid);

  .compact & {
    width: 50%;
  }
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas:
    "header-1   value-1"
    "header-2   value-2"
    "header-3   value-3"
    "header-4   value-4"
    "header-5   value-5"
    "header-6   value-6";

  .compact & {
    grid-template-columns: 1fr 2fr 1fr 1fr 2fr 1fr;
    grid-template-areas:
      "header-1 header-2   header-3 header-4   header-5   header-6"
      "value-1  value-2 value-3    value-4   value-5   value-6";
  }

  &:not(:last-child) {
    border-bottom: 1px var(--grid-border-type) var(--color-grid);
  }
`;

const GridItem = styled.div`
  padding: 3px;
  grid-area: value-${(props) => props.value};
  font-size: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px var(--grid-border-type) var(--color-grid);
  border-right: 0;

  &:nth-last-child(1),
  &:nth-last-child(2) {
    border-bottom: 0;
  }

  .compact & {
    border-right: 1px var(--grid-border-type) var(--color-grid);
    border-bottom: 0;

    &:nth-last-child(1),
    &:nth-last-child(2) {
      border-right: 0;
    }
  }
`;

const GridHeaderItem = styled(GridItem)`
  text-transform: uppercase;
  font-size: 8px;
  padding: 5px 0;
  grid-area: header-${(props) => props.value};
  border-right: 1px var(--grid-border-type) var(--color-grid);

  .compact & {
    border-bottom: 1px var(--grid-border-type) var(--color-grid);
  }
`;

const TypeStats = ({ type, flankSpeed, armour, hull, traits, flights }) => {
  return (
    <Container>
      <Grid>
        <GridHeaderItem value={1}>Type:</GridHeaderItem>
        <GridItem value={1}>{type}</GridItem>
        <GridHeaderItem value={2}>Flank Speed:</GridHeaderItem>
        <GridItem value={2}>{flankSpeed}</GridItem>
        <GridHeaderItem value={3}>Armour:</GridHeaderItem>
        <GridItem value={3}>{armour}</GridItem>
        <GridHeaderItem value={4}>Hull:</GridHeaderItem>
        <GridItem value={4}>{hull}</GridItem>
        <GridHeaderItem value={5}>Traits:</GridHeaderItem>
        <GridItem value={5}>{traits.map(traitWithValue).join(", ")}</GridItem>
        {flights && (
          <>
            <GridHeaderItem value={6}>Aircraft:</GridHeaderItem>
            <GridItem value={6}>{flights} Flights</GridItem>
          </>
        )}
      </Grid>
    </Container>
  );
};

export default TypeStats;
