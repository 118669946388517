import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const ImageHolder = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.25s;
  .box-container:hover & {
    transform: scale(1.1);
  }
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  overflow: hidden;
  border: 1px solid var(--color-grid);
  border-radius: 4px;
  display: flex;
  width: 100%;

  &:hover {
    cursor: pointer;
  }
`;

const Content = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 20px;
  z-index: 10;
  padding: 20px;

  background-color: rgba(0, 0, 0, 0.7);

  .box-container:hover & {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;

const Box = ({ image, children, ...props }) => {
  return (
    <Container {...props} className="box-container">
      <ImageHolder
        css={
          image &&
          css`
            background-image: url(${image});
          `
        }
      />
      <Content>{children}</Content>
    </Container>
  );
};

export default Box;
