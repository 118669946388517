import create from "zustand";

const useStore = create((set, get) => ({
  message: "",
  submitText: "",
  abortText: "",
  visible: false,
  onSubmit: null,
  onAbort: null,
  confirm: (
    message,
    onSubmit,
    onAbort,
    submitText = "Yes",
    abortText = "No"
  ) => {
    const state = get();
    set({
      message,
      submitText,
      abortText,
      visible: true,
      onSubmit: () => {
        if (onSubmit) {
          onSubmit();
        }
        state.clear();
      },
      onAbort: () => {
        if (onAbort) {
          onAbort();
        }
        state.clear();
      },
    });
  },
  clear: () => {
    set({
      message: "",
      submitText: "",
      abortText: "",
      visible: false,
      onSubmit: null,
      onAbort: null,
    });
  },
}));

export default useStore;
