import styled from "@emotion/styled";
import React, { useMemo } from "react";
import toast from "react-hot-toast";
import { getShipName } from "../../helper/helpers";
import Button, { ButtonGroup } from "../Button";
import Icon from "../Icon";
import useDatabaseStore from "../../stores/databaseStore";
import useConfirmStore from "../../stores/confirmStore";
import usePrintStore from "../../stores/printStore";
import api from "../../apis/api";

const Container = styled.div`
  border: 1px solid var(--color-lighter-grey);
  border-radius: 4px;
  display: flex;
  align-items: center;
`;

const InnerContainer = styled.div`
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  flex-grow: 1;
  gap: 20px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.1);
    cursor: pointer;
  }
`;

const Actions = styled.div`
  display: flex;
  border-left: 1px solid var(--color-lighter-grey);
`;

const Ship = ({ data, ...props }) => {
  const { confirm } = useConfirmStore((state) => ({ confirm: state.confirm }));

  const { ships, setShips } = useDatabaseStore((state) => ({
    ships: state.ships,
    setShips: state.setShips,
  }));

  const { shipIds, toggleShip } = usePrintStore((state) => ({
    shipIds: state.shipIds,
    toggleShip: state.toggleShip,
  }));

  const onShipDelete = async () => {
    const toastId = toast.loading("Deleting Ship...");

    await api.ships.delete(data);
    setShips(ships.filter((currentShip) => currentShip.id !== data.id));
    toast.success("Ship deleted!", {
      id: toastId,
    });
  };

  const isInPrint = useMemo(() => shipIds.includes(data?.id), [data, shipIds]);

  return (
    <Container>
      <InnerContainer {...props}>
        <span>{getShipName(data.name, data.class)}</span>
        <span>{data.points}</span>
      </InnerContainer>
      <Actions>
        <ButtonGroup>
          <span></span>
          <Button
            color="transparent"
            onClick={() => {
              toast.success(
                isInPrint
                  ? "Removed Ship from print list."
                  : "Added Ship to print list."
              );
              toggleShip(data.id);
            }}
          >
            <Icon type="print" crossed={isInPrint} />
          </Button>
          <Button
            color="transparent"
            onClick={() =>
              confirm("Do you really want to delete that ship?", onShipDelete)
            }
          >
            <Icon type="delete" />
          </Button>
        </ButtonGroup>
      </Actions>
    </Container>
  );
};

export default Ship;
