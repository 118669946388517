import styled from "@emotion/styled";
import React from "react";

const A4 = styled.div`
  & > div + div {
    margin-top: 40px;
  }

  @media print {
    min-height: 27.67cm;
    max-width: 230mm;
    padding-top: 10px;
    padding-bottom: 10px;
    position: relative;
    display: block; /* unhide all tabs */
    page-break-after: always;

    & > div {
      position: relative;
      display: block;
    }
  }
`;

const PaperSize = ({ children }) => {
  return <A4>{children}</A4>;
};

export default PaperSize;
