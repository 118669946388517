import styled from "@emotion/styled";
import React from "react";
import news from "../../news";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
  padding: 20px;
`;

const NewsTitle = styled.div`
  color: var(--color-accent);
  font-size: 24px;
`;

const NewsDate = styled.div`
  font-size: 12px;
  text-align: right;
  margin-top: 10px;
`;

const NewsMessage = styled.div`
  margin-top: 20px;
`;

const News = ({ latest }) => {
  const entries = latest
    ? [
        news.sort(function (a, b) {
          return new Date(b.date) - new Date(a.date);
        })[0],
      ]
    : news;
  return (
    <Container>
      {entries.map((news) => (
        <div>
          <NewsDate>{news.date}</NewsDate>
          <NewsTitle>{news.title}</NewsTitle>
          <NewsMessage>{news.message}</NewsMessage>
        </div>
      ))}
    </Container>
  );
};

export default News;
