import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import useDatabaseStore from "../../../stores/databaseStore";
import useConfirmStore from "../../../stores/confirmStore";
import Button from "../../Button";
import Input from "../../Input";
import Overlay from "../../Overlay";
import Select from "../../Select";
import Icon from "../../Icon";
import { useForm } from "react-hook-form";

const Tags = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 10px;
`;

const tagStyles = css`
  border-radius: 15px;
  border: 1px solid var(--color-lighter-grey);
  display: inline-flex;
  gap: 10px;
`;

const TraitTag = styled.div`
  ${tagStyles}
  padding: 5px 5px 5px 15px;
`;

const TraitTagButton = styled.div`
  ${tagStyles}
  padding: 5px 15px;
  border-style: dotted !important;
  border-width: 2px;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.2);
  }
`;

const Container = styled.div``;

const TraitSelector = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const TraitSelect = ({
  selectedTraits,
  value,
  onAdd,
  onDelete,
  onselectedTraits,
  ...props
}) => {
  const {
    register,
    resetField,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm();

  const { traits } = useDatabaseStore((state) => ({
    traits: state.traits,
  }));

  const { confirm } = useConfirmStore((state) => ({ confirm: state.confirm }));
  const [showDialog, setShowDialog] = useState(false);

  const selectedTraitId = watch("trait");

  const getTrait = useCallback(
    (traitId) => traits.find((trait) => trait.id === Number(traitId)),
    [traits]
  );

  const selectedTrait = useMemo(
    () => getTrait(selectedTraitId),
    [selectedTraitId, getTrait]
  );

  const selectableTraits = useMemo(
    () =>
      traits.filter(
        (trait) => !selectedTraits.find((_trait) => _trait.id === trait.id)
      ),
    [traits, selectedTraits.length]
  );

  useEffect(() => {
    reset();
  }, [selectedTraits.length]);

  const onSubmit = (data) => {
    onAdd(getTrait(data.trait), data.value);
    reset();
  };

  const deleteTrait = (traitId) => {
    onDelete(traitId);
  };

  useEffect(() => {
    if (selectedTraitId && !getTrait(selectedTraitId).has_value) {
      resetField("value");
    }
  }, [selectedTraitId, resetField, getTrait]);

  return (
    <>
      <Container {...props}>
        <Tags>
          {selectedTraits
            ? selectedTraits.map((trait) => (
                <TraitTag key={trait.id}>
                  <div>
                    {trait.name}
                    {trait.hasValue && ` ${trait.value}`}
                  </div>
                  <Button
                    color="simple"
                    circle
                    onClick={() =>
                      confirm("Do you really want to delete that trait?", () =>
                        deleteTrait(trait.id)
                      )
                    }
                  >
                    <Icon type="close" />
                  </Button>
                </TraitTag>
              ))
            : "No traits selected"}{" "}
          <TraitTagButton onClick={() => setShowDialog(true)}>
            + Add
          </TraitTagButton>
        </Tags>
      </Container>
      {selectableTraits.length > 0 && (
        <Overlay open={showDialog} onClose={() => setShowDialog(false)}>
          <h3>Add a Trait</h3>
          <form onSubmit={handleSubmit(onSubmit)}>
            <TraitSelector>
              <Select
                name="trait"
                //onChange={(event) => setSelectedTraitId(event.target.value)}
                options={selectableTraits.map((trait) => ({
                  value: trait.id,
                  name: trait.name,
                }))}
                register={register}
              ></Select>
              {((selectedTrait && selectedTrait.has_value) ||
                (!selectedTrait && selectableTraits[0].has_value)) && (
                <Input
                  label="Value"
                  name="value"
                  register={register}
                  required
                  type="number"
                  error={errors.value}
                />
              )}
              <Button type="submit">Add</Button>
            </TraitSelector>
          </form>
        </Overlay>
      )}
    </>
  );
};

export default TraitSelect;
