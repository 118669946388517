import styled from "@emotion/styled";
import React from "react";
import Navigation from "../Navigation";

const PageWrapper = styled.div`
  padding: 100px 0;
  margin: 0;
  min-height: 100vh;
  background-color: var(--color-background);

  @media print {
    padding: 0;
  }

  & * {
    font-family: "Lato", sans-serif;
  }

  @media print {
    background-color: white;
  }
`;

const Page = ({ children, withNavigation = true }) => {
  return (
    <PageWrapper>
      {withNavigation && <Navigation />}
      {children}
    </PageWrapper>
  );
};

export default Page;
