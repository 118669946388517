import styled from "@emotion/styled";
import React from "react";
import Flag from "../Flag";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: space-between;
`;

const Name = styled.div`
  display: flex;
  text-transform: uppercase;
  font-size: 24px;
`;

const Spacer = styled.div`
  display: flex;
  flex-grow: 1;
`;

const Points = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 1px solid var(--color-vas-red);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  * {
    color: var(--color-vas-red);
  }
`;

const PointsValue = styled.span`
  font-size: 16px;
`;

const PointsLabel = styled.span`
  font-size: 12px;
`;

const Headline = ({ country, points, children }) => {
  return (
    <Wrapper>
      <Flag country={country} />
      <Name>{children}</Name>
      <Spacer />
      <Points>
        <PointsValue>{points}</PointsValue>
        <PointsLabel>points</PointsLabel>
      </Points>
    </Wrapper>
  );
};

export default Headline;
