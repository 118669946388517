import React from "react";
import { Link } from "react-router-dom";
import Container from "../components/Container";
import useAuthStore from "../stores/authStore";

const Imprint = ({ withWrappers = true }) => {
  const { isAuthenticated } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
  }));

  const content = (
    <>
      <h1>Impressum</h1>

      <h2>Angaben gem&auml;&szlig; &sect; 5 TMG</h2>
      <p>
        Alexander Elert
        <br />
        Alte Schmiede 1<br />
        25582 Kaaks
      </p>

      <h2>Kontakt</h2>
      <p>
        Telefon: 01717039499
        <br />
        E-Mail: info@webdevalex.de
      </p>

      <h2>Redaktionell verantwortlich</h2>
      <p>Alexander Elert</p>

      <h2>EU-Streitschlichtung</h2>
      <p>
        Die Europ&auml;ische Kommission stellt eine Plattform zur
        Online-Streitbeilegung (OS) bereit:{" "}
        <a
          href="https://ec.europa.eu/consumers/odr/"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://ec.europa.eu/consumers/odr/
        </a>
        .<br /> Unsere E-Mail-Adresse finden Sie oben im Impressum.
      </p>

      <h2>
        Verbraucher&shy;streit&shy;beilegung/Universal&shy;schlichtungs&shy;stelle
      </h2>
      <p>
        Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren
        vor einer Verbraucherschlichtungsstelle teilzunehmen.
      </p>

      <p>
        Quelle: <a href="https://www.e-recht24.de">eRecht24</a>
      </p>
    </>
  );

  if (!withWrappers) {
    return content;
  }

  return (
    <Container>
      {!isAuthenticated() && <Link to="/">Back to Page</Link>}
      {content}
    </Container>
  );
};

export default Imprint;
