import styled from "@emotion/styled";
import React from "react";

const StyledSelect = styled.select`
  padding: 10px;
  background-color: var(--color-background);
  border: 1px solid var(--color-lighter-grey);
  border-radius: 4px;
`;

const StyledLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
`;

const Select = ({ name, options, label, register, ...props }) => {
  const SelectComponent = (
    <StyledSelect id={name} {...(register ? register(name) : {})} {...props}>
      {options.map((option, index) => (
        <option key={index} value={option.value}>
          {option.name}
        </option>
      ))}
    </StyledSelect>
  );

  if (label) {
    return (
      <Wrapper>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
        {SelectComponent}
      </Wrapper>
    );
  }

  return SelectComponent;
};

export default Select;
