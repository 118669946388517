import styled from "@emotion/styled";
import React from "react";
import Button from "../Button";
import Icon from "../Icon";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
  z-index: 20;
`;

const Header = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: -20px;
  margin-left: -20px;
  margin-right: -20px;
  margin-bottom: 20px;
`;

const Container = styled.div`
  background-color: var(--color-background);
  border: 1px solid var(--color-lighter-grey);
  border-radius: 4px;
  padding: 20px;
  position: relative;
  max-width: 1000px;
  min-width: 400px;
  z-index: 30;
`;

const Overlay = ({ children, open, onClose, showClose = true }) => {
  if (!open) {
    return null;
  }

  return (
    <Backdrop>
      <Container>
        {showClose && (
          <Header>
            <Button small corner color="simple" onClick={onClose}>
              <Icon type="close" />
            </Button>
          </Header>
        )}

        {children}
      </Container>
    </Backdrop>
  );
};

export default Overlay;
