import styled from "@emotion/styled";
import React from "react";
import ErrorMessage from "../ErrorMessage";

const StyledInput = styled.input`
  padding: 10px;
  background-color: transparent;
  border: 1px solid var(--color-lighter-grey);
  border-radius: 4px;
`;

const StyledLabel = styled.label`
  &:hover {
    cursor: pointer;
  }
`;

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  align-items: center;
`;

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = ({
  label,
  onChange,
  name,
  register,
  required,
  error,
  ...props
}) => {
  const validation = {};
  if (required) {
    validation.required = { value: true, message: "This field is required." };
  }
  const InputComponent = (
    <StyledInput
      id={name}
      {...(register ? register(name, validation) : {})}
      {...props}
    />
  );

  if (label) {
    return (
      <>
        <Wrapper>
          <StyledLabel htmlFor={name}>{label}</StyledLabel>
          <InputWrapper>
            {InputComponent}
            {error && <ErrorMessage>{error.message}</ErrorMessage>}
          </InputWrapper>
        </Wrapper>
      </>
    );
  }

  return InputComponent;
};

export default Input;
