import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import { Link } from "react-router-dom";
import Icon from "../Icon";

const LinkItem = styled(Link)`
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 14px;
  margin-bottom: 10px;
  text-decoration: none;
  color: var(--color-text);

  &:hover {
    color: var(--color-accent);
  }
`;

const iconStyles = css`
  height: 14px;
`;

const BackLink = ({ children, ...props }) => {
  return (
    <LinkItem {...props}>
      <Icon type="arrowLeft" css={iconStyles} />
      <span>{children}</span>
    </LinkItem>
  );
};

export default BackLink;
