import styled from "@emotion/styled";
import React, { useState } from "react";
import { supabase } from "../supabaseClient";
import backgroundImage from "../images/backgrounds/login-bg.jpg";
import Input from "../components/Input";
import Button from "../components/Button";
import { css } from "@emotion/react";
import { Link } from "react-router-dom";
import useConsentStore from "../stores/consentStore";
import toast from "react-hot-toast";
import { useForm } from "react-hook-form";
import Icon from "../components/Icon";

const LoginContainer = styled.div`
  background-color: var(--color-background-transparent);
  padding: 20px 50px;
  border-radius: 4px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
`;

const LoginContainerFooter = styled.div`
  border-top: 1px solid var(--color-lighter-grey);
  text-align: center;
  padding-top: 10px;
`;

const PageColumns = styled.div`
  margin-top: -100px;
  margin-bottom: -100px;
  min-height: 100vh;
  display: grid;
  grid-template-columns: 3fr 2fr;
  align-items: center;
`;

const LeftColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 100px;
`;
const RightColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 50px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url(${backgroundImage});
`;

const Explanation = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  max-width: 500px;
  text-align: center;

  & + & {
    margin-top: 50px;
  }
`;

const Imprint = styled.div`
  margin-top: 50px;
  text-align: center;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const buttonStyles = css`
  margin-top: 20px;
  margin-bottom: 25px;
`;

const Subline = styled.h3`
  color: var(--color-accent);
`;

const ExplanationItemWrapper = styled.div`
  display: flex;
  gap: 10px;

  &:nth-of-type(1) {
    margin-top: 50px;
  }

  & + & {
    margin-top: 20px;
  }
`;

const ExplanationItem = ({ children }) => {
  return (
    <ExplanationItemWrapper>
      <Icon type="check" color="accent" /> {children}
    </ExplanationItemWrapper>
  );
};

const Login = () => {
  const consentConfirmed = useConsentStore((state) => state.confirmed);

  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    if (!consentConfirmed) {
      toast.error(
        "You have to confirm the cookie consent to use this service."
      );
      return;
    }
    setLoading(true);
    const toastId = toast.loading("Sending login link...");
    try {
      const { error } = await supabase.auth.signInWithOtp({
        email: data.email,
      });
      if (error) throw error;
      toast.success("Check your email for the login link!", {
        id: toastId,
      });
    } catch (error) {
      toast.error(error.error_description || error.message, {
        id: toastId,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <PageColumns>
        <LeftColumn>
          <h1>Victory at Sea - Profile Cards Generator</h1>
          <Subline>Create your custom and unique Profile Cards</Subline>
          <Explanation>
            <ExplanationItem>Create custom cards</ExplanationItem>
            <ExplanationItem>Print your custom cards</ExplanationItem>

            <ExplanationItem>
              Use different card themes <i>comming soon</i>
            </ExplanationItem>
          </Explanation>
          <Explanation>
            <p>
              This tool is a fan creation for the Tabletop Game
              <strong>Victory at Sea</strong> from{" "}
              <a
                href="https://warlordgames.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Warlord Games
              </a>{" "}
              and NOT an official tool.
            </p>
          </Explanation>
          <Imprint>
            <Link to="/imprint">Imprint</Link>
          </Imprint>
        </LeftColumn>
        <RightColumn>
          <LoginContainer>
            <h2>Login</h2>
            <p className="description">
              Sign in via magic link with your email below
            </p>
            <Form onSubmit={handleSubmit(onSubmit)}>
              <Input
                name="email"
                label="E-Mail"
                register={register}
                required
                error={errors.email}
              />
              <Button css={buttonStyles} disabled={loading}>
                {loading ? "Sending magic link ..." : "Send magic link"}
              </Button>
            </Form>
            <LoginContainerFooter>
              You have no account?{" "}
              <a href="mailto: info@webdevalex.de">Request one</a>
            </LoginContainerFooter>
          </LoginContainer>
        </RightColumn>
      </PageColumns>
    </>
  );
};

export default Login;
