import { css } from "@emotion/react";
import styled from "@emotion/styled";

const Container = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;

  @media only screen and (max-width: 1199px) {
    padding-left: 10px;
    padding-right: 10px;
  }

  ${(props) =>
    props.a4
      ? css`
          max-width: 230mm;
        `
      : ""}
`;

export default Container;
