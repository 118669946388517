import create from "zustand";

const useStore = create((set, get) => ({
  confirmed: localStorage.getItem("cookie-consent-confirmed") === "true",
  confirm: () => {
    localStorage.setItem("cookie-consent-confirmed", "true");
    set({ confirmed: true });
  },
  decline: () => {
    localStorage.setItem("cookie-consent-confirmed", "false");
    set({ confirmed: false });
  },
}));

export default useStore;
