const issues = [
  {
    title: "No mobile version",
    date: "2022-11-18",
    description:
      "This site is currently only optimized for desktop resolution.",
    solution: "A mobile version will come later.",
  },
  {
    title: "Unneccessary empty page in print",
    date: "2022-11-18",
    description:
      "Sometimes when you print a couple of cards, the printer wants to print an empty page, which has the date and the domain on it.",
    solution:
      "Just skip the empty pages in the print dialog and check pages from x to y for the correct ones.",
  },
  {
    title: "Some elements not visible in print",
    date: "2022-11-18",
    description:
      "Elements like images and health ticks won't show up in the print preview and also on the print.",
    solution: `You need to check the Options "Print Backgrounds" in your print dialog. Otherwise some parts of the cards won't be printed.`,
  },
];

export default issues;
