import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";
import useConfirmStore from "../../../stores/confirmStore";
import useDatabaseStore from "../../../stores/databaseStore";
import Button from "../../Button";
import toast from "react-hot-toast";
import Icon from "../../Icon";
import api from "../../../apis/api";
import bucket from "../../../helper/bucket";

const Container = styled.div`
  border: 1px solid var(--color-lighter-grey);
  border-radius: 4px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PrewiewImage = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
`;

const DeleteButtonContainer = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const FileUploadInput = styled.input`
  display: none;
`;

const FileUploadLabel = styled.label`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  gap: 10px;
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.05);
  }
`;

const ImageUpload = ({ shipId, image }) => {
  const { confirm } = useConfirmStore((state) => ({ confirm: state.confirm }));
  const { updateShipImageOptimistic } = useDatabaseStore((state) => ({
    updateShipImageOptimistic: state.updateShipImageOptimistic,
  }));

  const uploadImage = async (event) => {
    const toastId = toast.loading("Uploading Image...");

    const shipFile = event.target.files[0];
    const fileType = getFileType(shipFile.name);
    const data = await bucket.upload(`${shipId}.${fileType}`, shipFile);
    const publicUrl = await bucket.getPublicUrl(data.path);

    await api.ships.updateImage(shipId, publicUrl);
    updateShipImageOptimistic(shipId, publicUrl);

    toast.success("Image uploaded!", {
      id: toastId,
    });
  };

  const deleteImage = async () => {
    const toastId = toast.loading("Deleting Image...");

    const fileType = getFileType(image);
    bucket.delete(`${shipId}.${fileType}`);

    await api.ships.updateImage(shipId, null);
    updateShipImageOptimistic(shipId, null);
    toast.success("Image deleted!", {
      id: toastId,
    });
  };

  const getFileType = (fileName) => {
    const parts = fileName.split(".");
    return parts[parts.length - 1];
  };

  return (
    <Container>
      {image ? (
        <PrewiewImage
          css={css`
            background-image: url("${image}");
          `}
        >
          <DeleteButtonContainer>
            <Button
              corner
              small
              color="simple"
              onClick={() =>
                confirm("Do you really want to delete that image?", deleteImage)
              }
            >
              <Icon type="delete" />
            </Button>
          </DeleteButtonContainer>
        </PrewiewImage>
      ) : (
        <FileUploadLabel className="custom-file-upload">
          <FileUploadInput
            type="file"
            name="image"
            accept="image/png, image/jpeg"
            onChange={(event) => {
              uploadImage(event);
            }}
          />
          <Icon type="upload" /> Choose an image
        </FileUploadLabel>
      )}
    </Container>
  );
};

export default ImageUpload;
