import styled from "@emotion/styled";
import React from "react";
import { getCountryImage } from "../../helper/flags";

const Header = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const ImageWrapper = styled.div`
  display: flex;
  width: 60px;
`;

const Content = styled.div`
  display: flex;
  flex-grow: 1;
`;

const CountryImage = styled.img`
  height: 30px;
`;

const FlaggedHeadline = ({ country, children }) => (
  <Header>
    {country && (
      <ImageWrapper>
        <CountryImage src={getCountryImage(country.name)} alt={country.name} />
      </ImageWrapper>
    )}
    <Content>{children}</Content>
  </Header>
);

export default FlaggedHeadline;
