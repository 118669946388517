import styled from "@emotion/styled";
import React, { useCallback } from "react";
import Country from "../components/Country";
import useDatabaseStore from "../stores/databaseStore";
import { useNavigate } from "react-router-dom";

const CountriesGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 20px;
`;

const Countries = () => {
  const navigate = useNavigate();
  const { countries, ships } = useDatabaseStore((state) => ({
    countries: state.countries,
    ships: state.ships,
  }));

  const getShipCount = useCallback(
    (countryId) => {
      return ships.filter((ship) => ship.country_id === countryId).length;
    },
    [countries, ships]
  );

  return (
    <>
      <h1>Countries</h1>
      {countries && (
        <CountriesGrid>
          {countries.map((country) => (
            <Country
              key={country.id}
              name={country.name}
              shipCount={getShipCount(country.id)}
              fleetName={country.fleet_name}
              onClick={() => navigate(`/countries/${country.id}/ships`)}
            />
          ))}
        </CountriesGrid>
      )}
    </>
  );
};

export default Countries;
