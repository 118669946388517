import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const Container = styled.div`
  position: relative;
  &::before {
    font-size: 10px;
    position: absolute;
    left: -5px;
    content: "🠔";
    top: -1px;
  }
`;

const CircleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1px;
  border: 1px solid var(--color-grid);
  border-radius: 50%;
  background-color: var(--color-grid);
  transform: rotate(-45deg);
  ${(props) =>
    props.arc &&
    props.arc > 90 &&
    css`
      background-color: var(--color-vas-red);
    `};
`;

const QuarterCircle = styled.div`
  width: 5px;
  height: 5px;
  background-color: var(--color-vas-red);
  border-radius: 0 0 5px 0;
  transform: rotate(
    ${(props) => {
      if (props.direction === "left") {
        return "90deg";
      }
      if (props.direction === "front") {
        return "180deg";
      }
      if (props.direction === "back") {
        return "0deg";
      }
      return "270deg";
    }}
  );

  ${(props) => props.filled && "background-color: white;"}
`;

const FiringArc = ({ directions }) => {
  const filled = {
    front: true,
    right: true,
    left: true,
    back: true,
  };
  Object.keys(filled).forEach((key) => {
    filled[key] = directions.includes(key);
  });

  return (
    <Container>
      <CircleContainer arc={directions.length * 90}>
        {Object.keys(filled).map((key) => {
          return (
            <QuarterCircle key={key} direction={key} filled={!filled[key]} />
          );
        })}
      </CircleContainer>
    </Container>
  );
};

export default FiringArc;
