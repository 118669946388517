import React, { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import ShipEditor from "../components/ShipEditor";
import useDatabaseStore from "../stores/databaseStore";

const ShipCreator = () => {
  const [searchParams] = useSearchParams();
  const { loading, initialLoadDone } = useDatabaseStore((state) => ({
    loading: state.loading,
    initialLoadDone: state.initialLoadDone,
  }));

  const hasCountry = searchParams.has("country");

  const country = useMemo(
    () => (hasCountry ? searchParams.get("country") : ""),
    [hasCountry, searchParams]
  );

  // If we come from a filled form, wait till the form is reset.

  return (
    <>
      {!loading && initialLoadDone && <ShipEditor countryPreselect={country} />}
    </>
  );
};

export default ShipCreator;
