import { supabase } from "../supabaseClient";

const bucket = {
  upload: async (filename, file) => {
    const { data, error } = await supabase.storage
      .from("ships")
      .upload(filename, file, {
        cacheControl: "3600",
        upsert: true,
      });

    return data;
  },
  delete: async (filename) => {
    await supabase.storage.from("ships").remove([filename]);
  },
  getPublicUrl: async (fileName) => {
    const { data } = await supabase.storage
      .from("ships")
      .getPublicUrl(fileName);

    return data.publicUrl;
  },
};

export default bucket;
