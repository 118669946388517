import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo } from "react";
import useDatabaseStore from "../../stores/databaseStore";
import usePrintStore from "../../stores/printStore";
import useConfirmStore from "../../stores/confirmStore";
import Input from "../Input";
import Select from "../Select";
import ImageUpload from "./ImageUpload";
import TraitSelect from "./TraitSelect";
import Button from "../Button";
import WeaponSystems from "./WeaponSystems";
import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import FlaggedHeadline from "../FlaggedHeadline";
import Icon from "../Icon";
import { getShipName } from "../../helper/helpers";
import api from "../../apis/api";
import { useForm } from "react-hook-form";

const HeadlineColumns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ColumnWrapper = styled.section`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

const Inputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Traits = styled.div`
  display: grid;
  grid-template-columns: 1fr 5fr;
  align-items: center;
`;

const InfoMessage = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 30px;
  background-color: rgba(255, 255, 255, 0.05);

  h3 {
    color: var(--color-accent);
  }

  & > p {
    width: 100%;
  }
`;

const LighterSection = styled.section`
  background-color: rgba(255, 255, 255, 0.05);
  margin-top: 40px;
  margin-bottom: 40px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 20px;
`;

const SaveButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
`;

const ShipEditor = ({ ship, countryPreselect }) => {
  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      ...(countryPreselect ? { country_id: countryPreselect } : {}),
    },
  });
  const navigate = useNavigate();

  const { shipIds, toggleShip } = usePrintStore((state) => ({
    shipIds: state.shipIds,
    toggleShip: state.toggleShip,
  }));

  const { confirm } = useConfirmStore((state) => ({ confirm: state.confirm }));

  const typeId = watch("type_id");

  const {
    ships,
    setShips,
    types,
    countries,
    reloadShips,
    addShipTraitOptimisitc,
    removeShipTraitOptimistic,
  } = useDatabaseStore((state) => ({
    ships: state.ships,
    setShips: state.setShips,
    types: state.types,
    countries: state.countries,
    reloadShips: state.reloadShips,
    addShipTraitOptimisitc: state.addShipTraitOptimisitc,
    removeShipTraitOptimistic: state.removeShipTraitOptimistic,
  }));

  const onShipTraitAdd = async (trait, value) => {
    const toastId = toast.loading("Adding Trait...");

    const traitObject = {
      id: trait.id,
      name: trait.name,
      hasValue: trait.has_value,
      value,
    };

    await api.ships.traits.add(ship.id, trait.id, value);
    addShipTraitOptimisitc(ship.id, traitObject);
    toast.success("Trait added!", {
      id: toastId,
    });
  };

  const onShipTraitDelete = async (traitId) => {
    const toastId = toast.loading("Deleting Trait...");

    await api.ships.traits.delete(ship.id, traitId);
    removeShipTraitOptimistic(ship.id, traitId);
    toast.success("Trait deleted!", {
      id: toastId,
    });
  };

  const onShipDelete = async () => {
    const toastId = toast.loading("Deleting Ship...");

    await api.ships.delete(ship);
    setShips(ships.filter((currentShip) => currentShip.id !== ship.id));
    navigate(`/countries/${ship.country_id}/ships`);
    toast.success("Ship deleted!", {
      id: toastId,
    });
  };

  const shipName = watch("name");
  const shipClass = watch("class");

  const shipHeadline = useMemo(() => {
    return getShipName(shipName ?? ship?.name, shipClass ?? ship?.class);
  }, [ship, shipName, shipClass]);

  const isInPrint = useMemo(() => shipIds.includes(ship?.id), [ship, shipIds]);

  const selectedCountry = useMemo(
    () =>
      countries.find(
        (country) =>
          Number(country.id) ===
          Number(
            watch("country_id") ||
              ship?.country_id ||
              countryPreselect ||
              countries[0].id
          )
      ),
    [countries, watch("country_id"), countryPreselect]
  );

  const onSubmit = async (data) => {
    const toastId = toast.loading(`${ship ? "Updating" : "Creating"} Ship...`);
    const payload = {
      country_id: data.country_id,
      class: data.class,
      name: data.name,
      points: data.points,
      type_id: data.type_id,
      flank_speed: data.flankSpeed,
      armour: data.armour,
      hull_health: data.hullHealth,
      hull_crippled: data.hullCrippled,
      flights: Number(data.type_id) === 2 ? data.flights : "",
    };
    if (ship) {
      await api.ships.update(ship.id, payload);
    } else {
      const createdShip = await api.ships.create(payload);
      if (createdShip.id) {
        await reloadShips();
        navigate(`/ships/${createdShip.id}`);
      }
    }
    toast.success(`Ship ${ship ? "saved" : "created"}!`, {
      id: toastId,
    });
  };

  return (
    <>
      <HeadlineColumns>
        <FlaggedHeadline country={selectedCountry}>
          <h1>{shipHeadline}</h1>
        </FlaggedHeadline>
        {ship && (
          <ButtonWrapper>
            <Button
              color="simple"
              onClick={() => {
                toast.success(
                  isInPrint
                    ? "Removed Ship from print list."
                    : "Added Ship to print list."
                );
                toggleShip(ship.id);
              }}
            >
              <Icon type="print" crossed={isInPrint} />
            </Button>
            <Button
              color="simple"
              onClick={() =>
                confirm("Do you really want to delete that ship?", onShipDelete)
              }
            >
              <Icon type="delete" />
            </Button>
          </ButtonWrapper>
        )}
      </HeadlineColumns>
      <h3>Stats</h3>
      <form onSubmit={handleSubmit(onSubmit)}>
        <ColumnWrapper>
          <Inputs>
            <Select
              name="country_id"
              label="Country"
              defaultValue={ship?.country_id}
              options={countries.map((country) => ({
                value: country.id,
                name: country.name,
              }))}
              register={register}
            />
            <Input
              defaultValue={ship?.class}
              label={"Class"}
              name="class"
              register={register}
              required
              error={errors.class}
            />

            <Input
              defaultValue={ship?.name}
              label={"Name"}
              name="name"
              register={register}
              error={errors.name}
            />
            <Input
              defaultValue={ship?.points}
              label={"Points"}
              name="points"
              register={register}
              required
              error={errors.points}
            />
            <Select
              label="Type"
              name="type_id"
              defaultValue={ship?.type_id}
              options={types.map((type) => ({
                value: type.id,
                name: type.name,
              }))}
              // onChange={handleChange}
              register={register}
              error={errors.type_id}
            />
            <Input
              label={"Flank Speed"}
              name="flankSpeed"
              type="number"
              defaultValue={ship?.flankSpeed}
              register={register}
              required
              error={errors.flankSpeed}
            />
            <Input
              label={"Armour"}
              name="armour"
              type="number"
              defaultValue={ship?.armour}
              register={register}
              required
              error={errors.armour}
            />
            <Input
              label={"Hull Health"}
              name="hullHealth"
              type="number"
              defaultValue={ship?.hullHealth}
              register={register}
              required
              error={errors.hullHealth}
            />
            <Input
              label={"Hull Crippled"}
              name="hullCrippled"
              type="number"
              defaultValue={ship?.hullCrippled}
              register={register}
              required
              error={errors.hullCrippled}
            />
            {(Number(ship?.type_id) === 2 || Number(typeId) === 2) && (
              <Input
                label={"Flights"}
                name="flights"
                type="number"
                defaultValue={ship?.flights}
                register={register}
                required
                error={errors.flights}
              />
            )}
            <SaveButtonWrapper>
              &nbsp;
              <Button type="submit">{ship ? "Save" : "Create Ship"}</Button>
            </SaveButtonWrapper>
          </Inputs>
          {ship ? (
            <ImageUpload shipId={ship.id} image={ship.image} />
          ) : (
            <InfoMessage>
              <h3>A small note!</h3>
              <p>
                Before you can add Traits, Weapon Systems or an image, you have
                to create a base Version of the Ship. Just fill the form with
                data and hit create.
              </p>
              <p>Then you can add all the good stuff to your Ship.</p>
            </InfoMessage>
          )}
        </ColumnWrapper>
      </form>
      {ship && (
        <>
          <Traits>
            Traits{" "}
            <TraitSelect
              css={css`
                margin-top: 20px;
              `}
              selectedTraits={ship.traits}
              onAdd={onShipTraitAdd}
              onDelete={onShipTraitDelete}
            />
          </Traits>
          <LighterSection>
            <WeaponSystems weaponSystems={ship.weaponSystems} />
          </LighterSection>
        </>
      )}
    </>
  );
};

export default ShipEditor;
