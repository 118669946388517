import React from "react";
import Container from "../components/Container";
import styled from "@emotion/styled";
import Box from "../components/Box";
import countriesImage from "../images/countries.jpg";
import shipEditorImage from "../images/ship-editor.jpg";
import { useNavigate } from "react-router-dom";
import News from "../components/News";

const BoxContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 50px;
  min-height: 180px;
  margin-top: 100px;
`;

const BoxText = styled.div`
  font-size: 20px;
`;

const Subline = styled.h3`
  color: var(--color-accent);
`;

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <Container>
        <h1>Victory at Sea - Profile Cards Generator</h1>
        <Subline>Create your custom and unique Profile Cards</Subline>

        <BoxContainer>
          <Box image={countriesImage} onClick={() => navigate("/countries")}>
            <BoxText>Discover Countries</BoxText>
          </Box>
          <Box image={shipEditorImage} onClick={() => navigate("/create")}>
            <BoxText>Create a Ship</BoxText>
          </Box>
          <News latest />
        </BoxContainer>
      </Container>
    </>
  );
};

export default Home;
