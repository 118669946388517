import styled from "@emotion/styled";
import React from "react";
import Button from "../Button";
import Overlay from "../Overlay";
import useConfirmStore from "../../stores/confirmStore";

const Buttons = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 20px;
`;

const IconWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const Icon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 20px;
  color: var(--color-text-invert);
  width: 40px;
  height: 40px;
  font-size: 36px;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 4fr;
  justify-content: space-around;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

const Confirm = () => {
  const { message, submitText, abortText, visible, onSubmit, onAbort } =
    useConfirmStore((state) => ({
      message: state.message,
      submitText: state.submitText,
      abortText: state.abortText,
      visible: state.visible,
      onSubmit: state.onSubmit,
      onAbort: state.onAbort,
    }));

  if (!visible) {
    return null;
  }
  return (
    <Overlay open showClose={false}>
      <Container>
        <IconWrapper>
          <Icon>?</Icon>
        </IconWrapper>
        <div>{message}</div>
      </Container>
      <Buttons>
        <Button color="simple" onClick={onAbort}>
          {abortText}
        </Button>
        <Button onClick={onSubmit}>{submitText}</Button>
      </Buttons>
    </Overlay>
  );
};

export default Confirm;
