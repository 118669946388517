import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";

const StepWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 40px;
`;

const Step = styled.div`
  display: flex;
  position: relative;
  position: relative;

  &::before {
    content: " ";
    width: 2px;
    height: 10px;
    background-color: var(--color-vas-red);
    position: absolute;
    left: 50%;
    transform: translate(-50%);
  }
`;

const HealthTicker = ({ max, tenth = false }) => {
  const [values, setValues] = useState([]);
  const healthStep = tenth ? 10 : 1;
  const min = tenth ? 10 : 0;

  const orientationStyles = css`
    ${tenth
      ? css`
          padding-top: 15px;
          &::before {
            top: 0;
          }
        `
      : css`
          padding-bottom: 15px;
          &::before {
            bottom: 0;
          }
        `}
  `;

  useEffect(() => {
    const steps = [];
    for (let i = min; i <= max; i += healthStep) {
      steps.push(i);
    }
    setValues(steps);
  }, [max]);

  return (
    <StepWrapper>
      {values.map((step, index) => (
        <Step css={orientationStyles} key={index}>
          {step}
        </Step>
      ))}
    </StepWrapper>
  );
};

export default HealthTicker;
