import React, { useMemo } from "react";
import Container from "../components/Container";
import ProfileCard from "../components/ProfileCard";
import usePrintStore from "../stores/printStore";
import useDatabaseStore from "../stores/databaseStore";
import styled from "@emotion/styled";
import Button from "../components/Button";
import toast from "react-hot-toast";
import PaperSize from "../components/PaperSize";
import Icon from "../components/Icon";

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media print {
    display: none;
  }
`;

const PrintItems = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 40px;
`;

const InfoBox = styled.div`
  border: 1px solid var(--color-grid);
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 40px;

  p {
    margin: 0;
  }

  @media print {
    display: none;
  }
`;

const InfoBoxHeadline = styled.h2`
  margin-top: 0;
  color: var(--color-accent);
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;

const PrintList = () => {
  const { loading, ships } = useDatabaseStore((state) => ({
    loading: state.loading,
    ships: state.ships,
  }));
  const { shipIds, clear } = usePrintStore((state) => ({
    shipIds: state.shipIds,
    clear: state.clear,
  }));

  const shipsToPrint = useMemo(() => {
    if (ships) {
      const filteredShips = ships.filter((ship) => shipIds.includes(ship.id));
      const toPrint = [];
      let touple = [];
      filteredShips.forEach((ship) => {
        touple.push(ship);

        if (touple.length === 2) {
          toPrint.push(touple);
          touple = [];
        }
      });
      if (touple.length > 0) {
        toPrint.push(touple);
      }
      return toPrint;
    }
    return [];
  }, [ships, shipIds]);

  return (
    <>
      {!loading && ships && (
        <Container>
          <Header>
            <h1>Print List</h1>
            <Buttons>
              <Button onClick={() => window.print()}>
                <Icon type="print" color="dark" />
              </Button>
              {shipsToPrint.length > 0 && (
                <Button
                  color="simple"
                  onClick={() => {
                    clear();
                    toast.success("Print List cleared.");
                  }}
                >
                  <Icon type="delete" />
                </Button>
              )}
            </Buttons>
          </Header>
          <InfoBox>
            <InfoBoxHeadline>Attention</InfoBoxHeadline>
            <p>
              You need to check the Options "Print Backgrounds" in your print
              dialog. Otherwise some parts of the cards won't be printed.
            </p>
          </InfoBox>
          {shipsToPrint.length === 0 && (
            <div>The print list is empty. Go and add some Ships!</div>
          )}
          {shipsToPrint.length > 0 && (
            <Container a4>
              <PrintItems>
                {shipsToPrint.map((touple, index) => {
                  return (
                    <PaperSize key={index}>
                      {touple.map((ship) => (
                        <ProfileCard key={ship.id} ship={ship} />
                      ))}
                    </PaperSize>
                  );
                })}
              </PrintItems>
            </Container>
          )}
        </Container>
      )}
    </>
  );
};

export default PrintList;
