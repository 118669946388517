import styled from "@emotion/styled";
import React from "react";

const Backdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-direction: column;
`;

const SpinnerIcon = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 50px;
  z-index: 100;

  animation: spin 2s linear infinite;
  @keyframes spin {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
    100% {
      transform: scale(1);
    }
  }
`;

const Spinner = () => {
  return (
    <Backdrop>
      <SpinnerIcon>𓀅</SpinnerIcon>
    </Backdrop>
  );
};

export default Spinner;
