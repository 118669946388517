import styled from "@emotion/styled";
import React from "react";
import Icon from "../Icon";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 0 20px;
`;

const IssueContainer = styled.div`
  margin-left: -20px;
  margin-right: -20px;
`;

const IssueTitle = styled.div`
  display: flex;
  justify-content: space-between;
  background-color: rgba(255, 255, 255, 0.05);
  padding: 20px;

  & > :nth-of-type(1) {
    color: var(--color-accent);
  }
`;

const IssueDate = styled.div`
  color: var(--color-text);
`;

const IssueItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 8fr;
  align-items: center;

  & > :nth-of-type(1) {
    display: flex;
    justify-content: center;
  }

  & > :nth-of-type(2) {
    padding: 20px 20px 20px 0;
  }

  & + & {
    margin-top: 20px;
  }
`;

const Issue = ({ issue }) => {
  return (
    <IssueContainer>
      <IssueTitle>
        <div>{issue.title}</div>
        <IssueDate>{issue.date}</IssueDate>
      </IssueTitle>

      {issue.description && (
        <IssueItem>
          <div>
            <Icon type="alert" />
          </div>
          <div>{issue.description}</div>
        </IssueItem>
      )}
      {issue.solution && (
        <IssueItem>
          <div>
            <Icon type="check" />
          </div>
          <div>{issue.solution}</div>
        </IssueItem>
      )}
    </IssueContainer>
  );
};

const Issues = ({ children, data, ...props }) => {
  return (
    <Container {...props}>
      {children}
      {data.map((issue) => (
        <Issue issue={issue} />
      ))}
    </Container>
  );
};

export default Issues;
