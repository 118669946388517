import styled from "@emotion/styled";
import React from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useAuthStore from "../../stores/authStore";
import usePrintStore from "../../stores/printStore";
import Button from "../Button";
import Icon from "../Icon";
import { css } from "@emotion/react";

const Navbar = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 80px;
  width: 100%;
  backdrop-filter: blur(5px);
  border-bottom: 1px solid var(--color-lighter-grey);
  z-index: 10;

  @media print {
    display: none;
  }
`;

const NavContainer = styled.div`
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  @media only screen and (max-width: 1199px) {
    padding-left: 10px;
    padding-right: 10px;
  }
`;

const NavItems = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
`;

const UserActions = styled.div`
  display: flex;
  gap: 20px;
`;

const NavItem = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 25px;
  height: 100%;
  text-decoration: none;

  color: var(--color-text);
  font-weight: bold;
  font-size: 14px;
  &:hover {
    color: var(--color-accent);
    border-bottom: 2px solid var(--color-accent);
    border-top: 2px solid transparent;

    & > div {
      color: var(--color-accent);
    }
  }
  ${(props) =>
    props.active &&
    css`
      color: var(--color-accent);
      border-top: 2px solid transparent;
      border-bottom: 2px solid var(--color-accent);
      & > div {
        color: var(--color-accent);
      }
    `}
`;

const Counter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 20px;
  height: 20px;
  border-radius: 4px;
  color: var(--color-text);
  border: 1px solid rgba(255, 255, 255, 0.2);
  font-weight: normal;
  margin-left: 10px;
`;

const UserName = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 5px 20px;
  border-radius: 4px;
  font-size: 12px;

  img {
    border: 1px solid var(--color-text);
    border-radius: 20px;
  }
`;

const Navigation = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { session, logout } = useAuthStore((state) => ({
    session: state.session,
    logout: state.logout,
  }));

  const { shipIds } = usePrintStore((state) => ({ shipIds: state.shipIds }));

  return (
    <Navbar>
      <NavContainer>
        <NavItems>
          <NavItem to="/" active={location.pathname === "/"}>
            🌊
          </NavItem>
          <NavItem
            to="/countries"
            active={
              location.pathname.startsWith("/countries") ||
              location.pathname.startsWith("/ships")
            }
          >
            Countries
          </NavItem>
          <NavItem to="/create" active={location.pathname === "/create"}>
            Create a Ship
          </NavItem>
          <NavItem
            to="/print-list"
            active={location.pathname === "/print-list"}
          >
            Print List{" "}
            {shipIds.length > 0 && <Counter>{shipIds.length}</Counter>}
          </NavItem>
          <NavItem to="/issues" active={location.pathname === "/issues"}>
            Issues
          </NavItem>
          <NavItem to="/imprint" active={location.pathname === "/imprint"}>
            Imprint
          </NavItem>
        </NavItems>
        <UserActions>
          {session ? (
            <>
              <UserName>
                <Icon type="user" /> {session.user.email}
              </UserName>
              <Button onClick={() => logout()}>
                <Icon type="logout" color="dark" /> <span>Logout</span>
              </Button>
            </>
          ) : (
            <Button onClick={() => navigate("/login")}>Login</Button>
          )}
        </UserActions>
      </NavContainer>
    </Navbar>
  );
};

export default Navigation;
