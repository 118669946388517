export const valueOrDash = (value, inch = false) =>
  value ? `${value}${inch ? '"' : ""}` : "-";

export const nullOrValue = (value) => {
  if (value === null) {
    return null;
  }
  if (typeof value !== "string") {
    return value;
  }
  return value.trim() === "" ? null : value;
};

export const nullOrNumber = (value) => {
  return nullOrValue(value) === null ? null : Number(value);
};

export const traitWithValue = (trait) => {
  return trait.name + (trait.hasValue ? ` ${trait.value}` : "");
};

export const getShipName = (name, shipClass) => {
  const parts = [];
  if (name) {
    parts.push(name);
  }
  if (shipClass) {
    parts.push(shipClass);
  }
  if (parts.length > 0) {
    return parts.join(" / ");
  }
  return "Unnamed Ship";
};

export const sortBy = (a, b, field) => sort(a[field], b[field]);

export const sort = (a, b) => {
  if (a < b) {
    return -1;
  }
  if (a > b) {
    return 1;
  }
  return 0;
};

export const newId = () =>
  Math.random() * Math.floor(Math.random() * Date.now());
