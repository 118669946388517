import { supabase } from "../supabaseClient";
import { nullOrNumber } from "../helper/helpers";

const api = {
  // ---------------------------------------
  // SHIPS
  // ---------------------------------------
  ships: {
    /**
     * Creates a ship.
     */
    create: async (shipData) => {
      const { data, error } = await supabase.from("ships").insert({
        country_id: nullOrNumber(shipData.country_id),
        class: shipData.class,
        name: shipData.name,
        points: nullOrNumber(shipData.points),
        type_id: nullOrNumber(shipData.type_id),
        flank_speed: nullOrNumber(shipData.flank_speed),
        armour: nullOrNumber(shipData.armour),
        hull_health: nullOrNumber(shipData.hull_health),
        hull_crippled: nullOrNumber(shipData.hull_crippled),
        flights: nullOrNumber(shipData.flights),
      });

      return data[0];
    },
    /**
     * Update only ship stats
     */
    update: async (shipId, shipData) => {
      const { data, error } = await supabase
        .from("ships")
        .update({
          country_id: nullOrNumber(shipData.country_id),
          class: shipData.class,
          name: shipData.name,
          points: nullOrNumber(shipData.points),
          type_id: nullOrNumber(shipData.type_id),
          flank_speed: nullOrNumber(shipData.flank_speed),
          armour: nullOrNumber(shipData.armour),
          hull_health: nullOrNumber(shipData.hull_health),
          hull_crippled: nullOrNumber(shipData.hull_crippled),
          flights: nullOrNumber(shipData.flights),
        })
        .eq("id", shipId);

      return data;
    },
    updateImage: async (shipId, path) => {
      const { data, error } = await supabase
        .from("ships")
        .update({
          image: path,
        })
        .eq("id", shipId);
    },
    /**
     * Delete the ship and all of it's relations.
     */
    delete: async (ship) => {
      const weaponSystemIds = ship.weaponSystems.map(
        (weaponSystem) => weaponSystem.id
      );
      await Promise.all([
        // Delete weapon system traits
        await supabase
          .from("traits_weapon_systems")
          .delete()
          .in("weapon_system_id", weaponSystemIds),
        // Delete weapon system directions
        await supabase
          .from("directions_weapon_systems")
          .delete()
          .in("weapon_system_id", weaponSystemIds),
      ]);

      await Promise.all([
        // Delete weapon systems
        await supabase.from("weapon_systems").delete().eq("ship_id", ship.id),
        // Delete ship traits
        await supabase.from("ship_traits").delete().eq("ship_id", ship.id),
      ]);

      await supabase.from("ships").delete().eq("id", ship.id);
    },
    find: async (shipId) => {
      let { data, error } = await supabase
        .from("ships")
        .select(
          `*,
            ship_traits(value, traits(id, name, has_value)),
            weapon_systems(
              *,
              directions_weapon_systems(directions(id, name)),
              traits_weapon_systems(value, traits(id, name, has_value))
              ), 
            ship_types(*),
            countries(*)`
        )
        .eq("id", shipId);
      if (!error) {
        return data[0];
      }

      return null;
    },
    all: async () => {
      let { data, error } = await supabase
        .from("ships")
        .select(
          `*,
            ship_traits(value, traits(id, name, has_value)),
            weapon_systems(
              *,
              directions_weapon_systems(directions(id, name)),
              traits_weapon_systems(value, traits(id, name, has_value))
              ), 
            ship_types(*),
            countries(*)`
        )
        .order("name", true)
        .order("type_id", true);
      if (!error) {
        return data;
      }

      return null;
    },
    // ---------------------------------------
    // SHIPS.TRAITS
    // ---------------------------------------
    traits: {
      /**
       * Insert the given trait for the given ship.
       */
      add: async (shipId, traitId, value) => {
        const { data, error } = await supabase
          .from("ship_traits")
          .insert([{ ship_id: shipId, trait_id: traitId, value }]);
      },
      /**
       * Delete the given trait from the given ship.
       */
      delete: async (shipId, traitId) => {
        const { data, error } = await supabase
          .from("ship_traits")
          .delete()
          .match({ ship_id: shipId, trait_id: traitId });
      },
    },
  },

  // ---------------------------------------
  //  SHIPS.WEAPON-SYSTEMS
  // ---------------------------------------
  weaponSystems: {
    create: async (shipId, stats) => {
      const { data, error } = await supabase.from("weapon_systems").insert({
        ship_id: shipId,
        name: stats.name,
        point_blank: nullOrNumber(stats.pointBlank),
        short: nullOrNumber(stats.short),
        long: nullOrNumber(stats.long),
        extreme: nullOrNumber(stats.extreme),
        attack_dice: nullOrNumber(stats.ad),
        armour_piercing: nullOrNumber(stats.ap),
        damage_dice: nullOrNumber(stats.dd),
      });

      await api.weaponSystems.directions.add(data[0].id, stats.directions);
      const weaponSystem = await api.weaponSystems.find(data[0].id);

      return weaponSystem;
    },
    /**
     * Update the weapon systems stats
     */
    update: async (weaponSystemId, payload) => {
      const { data, error } = await supabase
        .from("weapon_systems")
        .update({
          name: payload.name,
          point_blank: nullOrNumber(payload.pointBlank),
          short: nullOrNumber(payload.short),
          long: nullOrNumber(payload.long),
          extreme: nullOrNumber(payload.extreme),
          attack_dice: nullOrNumber(payload.ad),
          armour_piercing: nullOrNumber(payload.ap),
          damage_dice: nullOrNumber(payload.dd),
        })
        .match({ id: weaponSystemId });
    },
    find: async (weaponSystemId) => {
      let { data, error } = await supabase
        .from("weapon_systems")
        .select(
          `*,
          directions_weapon_systems(directions(id, name)),
          traits_weapon_systems(value, traits(id, name, has_value))
          )`
        )
        .eq("id", weaponSystemId);
      if (!error) {
        return data[0];
      }

      return null;
    },
    duplicate: async (shipId, weaponSystem) => {
      const duplicatedWeaponSystem = await api.weaponSystems.create(shipId, {
        name: weaponSystem.name,
        pointBlank: weaponSystem.pointBlank,
        short: weaponSystem.short,
        long: weaponSystem.long,
        extreme: weaponSystem.extreme,
        ad: weaponSystem.ad,
        ap: weaponSystem.ap,
        dd: weaponSystem.dd,
        directions: weaponSystem.directions.map((direction) => direction.id),
      });
      if (weaponSystem.traits.length > 0) {
        const duplicatedTraits = await api.weaponSystems.traits.addBatch(
          duplicatedWeaponSystem.id,
          weaponSystem.traits.map((trait) => ({
            id: trait.id,
            value: trait.value,
          }))
        );
        return {
          ...duplicatedWeaponSystem,
          traits: duplicatedTraits,
        };
      }

      return duplicatedWeaponSystem;
    },
    delete: async (weaponSystem, directions) => {
      // Delete all directions for this weapon system
      if (weaponSystem.directions.length > 0) {
        await api.weaponSystems.directions.delete(
          weaponSystem.id,
          directions.map((direction) => direction.id)
        );
      }

      // Delete all traits
      if (weaponSystem.traits.length > 0) {
        await api.weaponSystems.traits.deleteBatch(weaponSystem.id);
      }

      // Delete the weapon system
      await supabase.from("weapon_systems").delete().eq("id", weaponSystem.id);
    },

    // ---------------------------------------
    // SHIPS.WEAPON-SYSTEMS.TRAITS
    // ---------------------------------------

    traits: {
      add: async (weaponSystemId, traitId, value) => {
        const { data, error } = await supabase
          .from("traits_weapon_systems")
          .insert([
            { weapon_system_id: weaponSystemId, trait_id: traitId, value },
          ]);
      },
      addBatch: async (weaponSystemId, traits) => {
        const { data, error } = await supabase
          .from("traits_weapon_systems")
          .insert(
            traits.map((trait) => ({
              weapon_system_id: weaponSystemId,
              trait_id: trait.id,
              value: trait.value,
            }))
          );

        return data[0];
      },
      /**
       * Delete the given trait from the given weapon system.
       */
      delete: async (weaponSystemId, traitId) => {
        const { data, error } = await supabase
          .from("traits_weapon_systems")
          .delete()
          .match({ weapon_system_id: weaponSystemId, trait_id: traitId });
      },
      /**
       * Delete the given trait from the given weapon system.
       */
      deleteBatch: async (weaponSystemId) => {
        const { data, error } = await supabase
          .from("traits_weapon_systems")
          .delete()
          .eq("weapon_system_id", weaponSystemId);
      },
    },
    // ---------------------------------------
    // SHIPS.WEAPON-SYSTEMS.DIRECTIONS
    // ---------------------------------------
    directions: {
      /**
       * Add the given directions to the weapon system directsions.
       */
      add: async (weaponSystemId, directions) => {
        const { data, error } = await supabase
          .from("directions_weapon_systems")
          .insert(
            directions.map((direction) => ({
              weapon_system_id: weaponSystemId,
              direction_id: direction,
            }))
          );

        return data[0];
      },
      /**
       * Delete the given directions to the weapon system directsions.
       */
      delete: async (weaponSystemId, directions) => {
        const { data, error } = await supabase
          .from("directions_weapon_systems")
          .delete()
          .in("direction_id", directions)
          .eq("weapon_system_id", weaponSystemId);
      },
      /**
       * Update weapon system directions.
       */
      update: async (weaponSystemId, directionsToAdd, directionsToDelete) => {
        if (directionsToAdd.length > 0 && directionsToDelete.length > 0) {
          await Promise.all([
            await api.weaponSystems.directions.add(
              weaponSystemId,
              directionsToAdd
            ),
            await api.weaponSystems.directions.delete(
              weaponSystemId,
              directionsToDelete
            ),
          ]).catch((error) => {
            console.error(error.message);
          });
        } else if (directionsToAdd.length > 0) {
          await api.weaponSystems.directions.add(
            weaponSystemId,
            directionsToAdd
          );
        } else if (directionsToDelete.length > 0) {
          await api.weaponSystems.directions.delete(
            weaponSystemId,
            directionsToDelete
          );
        }
      },
      all: async (weaponSystemId) => {
        let { data, error } = await supabase
          .from("directions_weapon_systems")
          .select(`id, direction_id`)
          .match({ weapon_system_id: weaponSystemId });
        if (!error) {
          return data;
        }
      },
    },
  },

  // ---------------------------------------
  // Countries
  // ---------------------------------------
  countries: {
    all: async () => {
      let { data, error } = await supabase
        .from("countries")
        .select(`id, name, fleet_name`);
      if (!error) {
        return data;
      }

      return null;
    },
  },

  // ---------------------------------------
  // Traits
  // ---------------------------------------
  traits: {
    all: async () => {
      let { data, error } = await supabase
        .from("traits")
        .select(`id, name, has_value`);
      if (!error) {
        return data;
      }

      return null;
    },
  },

  // ---------------------------------------
  // Types
  // ---------------------------------------
  types: {
    all: async () => {
      let { data, error } = await supabase
        .from("ship_types")
        .select(`id, name`);
      if (!error) {
        return data;
      }

      return null;
    },
  },

  // ---------------------------------------
  // Directions
  // ---------------------------------------
  directions: {
    all: async () => {
      let { data, error } = await supabase
        .from("directions")
        .select(`id, name`);
      if (!error) {
        return data;
      }

      return null;
    },
  },
};

export default api;
