import styled from "@emotion/styled";
import React, { useState } from "react";
import Button from "../Button";
import useConsentStore from "../../stores/consentStore";

const Consent = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: var(--color-lighter-grey);
`;

const Buttons = styled.div`
  display: flex;
  gap: 20px;
`;

const CookieConsent = () => {
  const { confirmed, confirm, decline } = useConsentStore((state) => ({
    confirmed: state.confirmed,
    confirm: state.confirm,
    decline: state.decline,
  }));
  const [open, setOpen] = useState(!confirmed);
  const onAccept = () => {
    confirm();
    setOpen(false);
  };

  const onDecline = () => {
    decline();
    setOpen(false);
  };

  if (!open) {
    return null;
  }

  return (
    <Consent>
      This site uses cookies to ensure certain features to work, like the option
      to log in. This tracking is always enabled, otherwise, you can't view this
      website.
      <Buttons>
        <Button onClick={() => onAccept()}>Accept</Button>
        <Button onClick={() => onDecline()}>Decline</Button>
      </Buttons>
    </Consent>
  );
};

export default CookieConsent;
