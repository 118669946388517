import styled from "@emotion/styled";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import BackLink from "../components/BackLink";
import Button from "../components/Button";
import FlaggedHeadline from "../components/FlaggedHeadline";
import Ship from "../components/Ship";

import useDatabaseStore from "../stores/databaseStore";

const ShipList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Columns = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Ships = () => {
  let { countryId } = useParams();
  const navigate = useNavigate();
  const [countryShips, setCountryShips] = useState(null);
  const [country, setCountry] = useState(null);
  const { countries, ships, loading } = useDatabaseStore((state) => ({
    countries: state.countries,
    ships: state.ships,
    loading: state.loading,
  }));

  useEffect(() => {
    if (!loading && countries && ships) {
      if (countryId) {
        setCountry(
          countries.find((country) => country.id === Number(countryId))
        );
        setCountryShips(
          ships
            .filter((ship) => ship.country_id === Number(countryId))
            .reduce(function (prev, current) {
              prev[current.type] = prev[current.type] || [];
              prev[current.type].push(current);
              return prev;
            }, Object.create(null))
        );
      }
    }
  }, [ships, countryId, loading]);

  return (
    <>
      {country && (
        <>
          <BackLink to="/countries">Countries</BackLink>
          <FlaggedHeadline country={country}>
            <Columns>
              <h1>
                {country.name} - {country.fleet_name}
              </h1>
              <Button
                color="simple"
                onClick={() => navigate(`/create?country=${countryId}`)}
              >
                Create a Ship
              </Button>
            </Columns>
          </FlaggedHeadline>

          {countryShips && (
            <>
              {Object.keys(countryShips).map((shipType) => (
                <React.Fragment key={shipType}>
                  <h2>{shipType}</h2>
                  <ShipList>
                    {countryShips[shipType].map((ship) => (
                      <Ship
                        key={ship.id}
                        data={ship}
                        onClick={() => navigate(`/ships/${ship.id}`)}
                      />
                    ))}
                  </ShipList>
                </React.Fragment>
              ))}
              {Object.keys(countryShips).length === 0 && (
                <div>No ships for this Country found</div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default Ships;
