import styled from "@emotion/styled";
import React from "react";

import editIcon from "./icons/edit.svg";
import loginIcon from "./icons/login.svg";
import logoutIcon from "./icons/logout.svg";
import printIcon from "./icons/printer.svg";
import saveIcon from "./icons/save.svg";
import deleteIcon from "./icons/trash.svg";
import uploadIcon from "./icons/upload.svg";
import closeIcon from "./icons/close.svg";
import copyIcon from "./icons/copy.svg";
import userIcon from "./icons/user.svg";
import alertIcon from "./icons/alert.svg";
import checkIcon from "./icons/check.svg";
import arrowLeftIcon from "./icons/arrow-left.svg";
import sendIcon from "./icons/send.svg";

const icons = {
  edit: editIcon,
  login: loginIcon,
  logout: logoutIcon,
  print: printIcon,
  save: saveIcon,
  delete: deleteIcon,
  upload: uploadIcon,
  close: closeIcon,
  copy: copyIcon,
  user: userIcon,
  alert: alertIcon,
  check: checkIcon,
  arrowLeft: arrowLeftIcon,
  send: sendIcon,
};

const IconContainer = styled.div`
  display: flex;
  width: 20px;
  height: 20px;
  position: relative;
`;

const Crossed = styled.div`
  position: absolute;
  width: 10px;
  height: 10px;
  top: -7px;
  right: -7px;
`;

const IconElement = styled.img`
  width: 100%;
  height: 100%;
`;

const LogoutIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-log-out"
    >
      <path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4"></path>
      <polyline points="16 17 21 12 16 7"></polyline>
      <line x1="21" y1="12" x2="9" y2="12"></line>
    </svg>
  );
};

const PrintIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="feather feather-printer"
    >
      <polyline points="6 9 6 2 18 2 18 9"></polyline>
      <path d="M6 18H4a2 2 0 0 1-2-2v-5a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2h-2"></path>
      <rect x="6" y="14" width="12" height="8"></rect>
    </svg>
  );
};

const SendIcon = ({ color }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke={color}
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      class="feather feather-send"
    >
      <line x1="22" y1="2" x2="11" y2="13"></line>
      <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
    </svg>
  );
};

const colors = {
  light: "#EDEDED",
  dark: "#1c1c1c",
  accent: "var(--color-accent)",
};

const Icon = ({ type, color = "light", crossed = false, ...props }) => {
  if (!icons[type]) {
    return null;
  }

  return (
    <IconContainer {...props}>
      {crossed && (
        <Crossed>
          <IconElement src={icons.close} alt={"Delete"} />
        </Crossed>
      )}
      {type === "logout" ? (
        <LogoutIcon color={colors[color] || colors.light} />
      ) : type === "print" ? (
        <PrintIcon color={colors[color] || colors.light} />
      ) : type === "send" ? (
        <SendIcon color={colors[color] || colors.light} />
      ) : (
        <IconElement src={icons[type]} alt={type} />
      )}
    </IconContainer>
  );
};

export default Icon;
