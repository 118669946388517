import dayjs from "dayjs";

export const storages = {
  countries: "countries",
  directions: "directions",
  types: "types",
  traits: "traits",
  ships: "ships",
  images: "images",
  print: "print",
};

class Storage {
  constructor(storage) {
    this.storage = storage;
  }

  store(value) {
    localStorage.setItem(this.storage, JSON.stringify(value));
    localStorage.setItem(
      `${this.storage}_valid_till`,
      dayjs().add(1, "day").format()
    );
  }

  needsRefresh() {
    const validTill = localStorage.getItem(`${this.storage}_valid_till`);
    if (validTill) {
      return dayjs(new Date()).isAfter(validTill);
    }

    return true;
  }

  get() {
    const data = localStorage.getItem(this.storage);
    if (data) {
      return JSON.parse(data);
    }
    return null;
  }

  find(id) {
    const items = this.get(this.storage);
    if (items) {
      return items.find((item) => item.id === id);
    }
    return null;
  }

  replace(id, value) {
    const items = this.get(this.storage);
    this.store(
      this.storage,
      items.map((item) => (item.id === id ? value : item))
    );
  }
}

export default Storage;
