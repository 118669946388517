import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React from "react";

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px 20px;
  background-color: var(--color-accent);
  border: 0;
  border-radius: 4px;
  color: var(--color-text-invert);
  justify-content: center;

  &:hover {
    cursor: pointer;
    filter: brightness(85%);
  }

  &:disabled {
    background-color: var(--color-background);
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: var(--color-text);
  }
`;

const colors = {
  primary: css`
    &,
    & > * {
      color: var(--color-text-invert);
    }
    background-color: var(--color-accent);
    border: 1px solid rgba(0, 0, 0, 0.2);
  `,
  simple: css`
    &,
    & > * {
      color: var(--color-text);
    }
    background-color: var(--color-lighter-grey);
    border: 1px solid rgba(255, 255, 255, 0.2);
  `,
  transparent: css`
    &,
    & > * {
      color: var(--color-text);
    }
    background-color: transparent;
  `,
};

export const ButtonGroup = styled.div`
  display: flex;

  button:not(:first-of-type):not(:last-child) {
    border-radius: 0;
  }
  button:first-of-type {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
`;

const Button = ({
  children,
  color = "primary",
  small = false,
  circle = false,
  corner = false,
  ...props
}) => {
  const buttonStyles = css`
    display: flex;
    justify-content: center;
    align-items: center;
    ${colors[color] ?? colors.primary}
    ${small &&
    css`
      padding: 5px 10px;
    `}
    ${circle &&
    css`
      border-radius: 50%;
      width: 20px;
      height: 20px;
      padding: 5px;
      &:hover {
        background-color: rgba(255, 255, 255, 0.2);
      }
    `}
    ${corner &&
    css`
      border-top-left-radius: 0;
      border-bottom-right-radius: 0;
    `}
  `;

  return (
    <StyledButton css={buttonStyles} title={props.title} {...props}>
      {children}
    </StyledButton>
  );
};

export default Button;
