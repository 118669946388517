import styled from "@emotion/styled";
import React from "react";
import { traitWithValue, valueOrDash } from "../../../helper/helpers";
import FiringArc from "./FiringArc";

const Container = styled.div`
  border: 1px var(--grid-border-type) var(--color-grid);
`;
const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
  &:not(:last-child) {
    border-bottom: 1px var(--grid-border-type) var(--color-grid);
  }
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3px 0;
  font-size: 10px;
  &:not(:last-child) {
    border-right: 1px var(--grid-border-type) var(--color-grid);
  }
`;

const GridHeaderItem = styled(GridItem)`
  text-transform: uppercase;
  font-size: 8px;
  padding: 5px 0;
`;

const WeaponSystems = ({ weaponSystems }) => {
  return (
    <Container>
      <Grid>
        <GridHeaderItem>Weapons System</GridHeaderItem>
        <GridHeaderItem>Fire Arc</GridHeaderItem>
        <GridHeaderItem>Point Blank</GridHeaderItem>
        <GridHeaderItem>Short</GridHeaderItem>
        <GridHeaderItem>Long</GridHeaderItem>
        <GridHeaderItem>Extreme</GridHeaderItem>
        <GridHeaderItem>AD</GridHeaderItem>
        <GridHeaderItem>AP</GridHeaderItem>
        <GridHeaderItem>DD</GridHeaderItem>
        <GridHeaderItem>Traits</GridHeaderItem>
      </Grid>
      {weaponSystems.map((weaponSystem, index) => (
        <Grid key={index}>
          <GridItem>{weaponSystem.name}</GridItem>
          <GridItem>
            <FiringArc
              directions={weaponSystem.directions.map(
                (direction) => direction.name
              )}
            />
          </GridItem>
          <GridItem>{valueOrDash(weaponSystem.pointBlank, true)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.short, true)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.long, true)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.extreme, true)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.ad)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.ap)}</GridItem>
          <GridItem>{valueOrDash(weaponSystem.dd)}</GridItem>
          <GridItem>
            {valueOrDash(weaponSystem.traits.map(traitWithValue).join(", "))}
          </GridItem>
        </Grid>
      ))}
    </Container>
  );
};

export default WeaponSystems;
