import styled from "@emotion/styled";
import React from "react";
import Headline from "./Headline";
import ShipImage from "./ShipImage/indedx";
import HealthTicker from "./Stepper";
import TypeStats from "./TypeStats";
import WeaponSystems from "./WeaponSystems";

const Container = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid black;
  border-radius: 4px;
  padding: 0 20px;

  & * {
    color: black;
    font-family: "Faustina", serif !important;
    font-weight: 300;
  }

  @media print {
    display: table;
  }
`;

const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  min-height: 400px;
  padding: 20px;
  gap: 20px;
`;

const StatsImageWrapper = styled.div`
  display: flex;
  gap: 20px;
  align-items: stretch;
`;

const ProfileCard = ({ ship }) => {
  return (
    <Container
      className={`${ship.weaponSystems.length > 7 ? "compact" : ""} ${
        ship.flights && "has-flights"
      }`}
    >
      <HealthTicker max={ship.hullHealth} tenth />
      <CardContent>
        <Headline country={ship.country} points={ship.points}>
          {ship.name ? ship.name : ship.class}
        </Headline>
        <StatsImageWrapper>
          <TypeStats
            type={ship.type}
            flankSpeed={ship.flankSpeed}
            armour={ship.armour}
            hull={ship.hull}
            traits={ship.traits}
            flights={ship.flights}
          />
          {ship.image && <ShipImage image={ship.image} />}
        </StatsImageWrapper>
        <WeaponSystems weaponSystems={ship.weaponSystems} />
      </CardContent>
      <HealthTicker max={9} />
    </Container>
  );
};

export default ProfileCard;
