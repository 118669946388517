import { css } from "@emotion/react";
import styled from "@emotion/styled";
import React, { useMemo, useState } from "react";
import { valueOrDash } from "../../../helper/helpers";
import Button from "../../Button";
import Overlay from "../../Overlay";
import FiringArc from "../../ProfileCard/WeaponSystems/FiringArc";
import WeaponSystemForm from "./WeaponSystemForm";
import useDatabaseStore from "../../../stores/databaseStore";
import { useParams } from "react-router-dom";

const Container = styled.div``;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Grid = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 2fr 1fr 1fr 1fr 1fr 1fr 1fr 3fr;
`;

const GridRow = styled(Grid)`
  &:hover {
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.1);
  }
`;

const GridItem = styled.div`
  padding: 5px 0;
  ${(props) =>
    css`
      text-align: ${props.align ?? "center"};
    `}
`;

const OverlayContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 600px;
`;

const WeaponSystems = ({ weaponSystems }) => {
  let { shipId } = useParams();
  const [showDialog, setShowDialog] = useState(false);
  const [selectedWeaponSystemId, setSelectedWeaponSystemId] = useState(null);
  const { ships, reloadShip } = useDatabaseStore((state) => ({
    ships: state.ships,
    reloadShip: state.reloadShip,
  }));

  const selectedWeaponSystem = useMemo(
    () =>
      weaponSystems.find(
        (weaponSystem) => weaponSystem.id === selectedWeaponSystemId
      ),
    [selectedWeaponSystemId, ships, weaponSystems]
  );

  const onFormFinish = () => {
    closeDialog();
    reloadShip(shipId);
  };

  const closeDialog = () => {
    setShowDialog(false);
  };

  return (
    <>
      <Container>
        <Header>
          <h3>Weapon Systems</h3>
          <Button
            onClick={() => {
              setSelectedWeaponSystemId(null);
              setShowDialog(true);
            }}
          >
            Add
          </Button>
        </Header>

        <Grid>
          <GridItem align="left">Name</GridItem>
          <GridItem>Fire Arc</GridItem>
          <GridItem>Point Blank</GridItem>
          <GridItem>Short</GridItem>
          <GridItem>Long</GridItem>
          <GridItem>Extreme</GridItem>
          <GridItem>AD</GridItem>
          <GridItem>AP</GridItem>
          <GridItem>DD</GridItem>
          <GridItem>Traits</GridItem>
        </Grid>

        {weaponSystems.map((weaponSystem) => (
          <GridRow
            key={weaponSystem.id}
            onClick={() => {
              setSelectedWeaponSystemId(weaponSystem.id);
              setShowDialog(true);
            }}
          >
            <GridItem align="left">{weaponSystem.name}</GridItem>
            <GridItem
              css={css`
                display: flex;
                justify-content: center;
                align-items: center;
              `}
            >
              <FiringArc
                directions={weaponSystem.directions.map(
                  (direction) => direction.name
                )}
              />
            </GridItem>
            <GridItem>{valueOrDash(weaponSystem.pointBlank, true)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.short, true)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.long, true)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.extreme, true)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.ad)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.ap)}</GridItem>
            <GridItem>{valueOrDash(weaponSystem.dd)}</GridItem>
            <GridItem align="left">
              {weaponSystem.traits
                .map((trait) => {
                  const name = trait.name;
                  if (trait.hasValue) {
                    return `${trait.name} ${trait.value}`;
                  }
                  return name;
                })
                .join(", ")}
            </GridItem>
          </GridRow>
        ))}
      </Container>
      <Overlay open={showDialog} onClose={closeDialog}>
        <OverlayContent>
          <WeaponSystemForm
            shipId={shipId}
            weaponSystem={selectedWeaponSystem}
            onFormFinish={onFormFinish}
          />
        </OverlayContent>
      </Overlay>
    </>
  );
};

export default WeaponSystems;
